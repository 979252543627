'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import { useReporter } from '../../../reporter';
import { useOffer } from '../../../offer';
import { headerBannerClicked } from './events';
import { StyledDiscountBanner, OfferBannerText, OfferLink } from './styles';

export const DiscountBanner = ({
  sticky = false,
  croCopy,
  bgColor = 'background.dark',
  fontColor = 'text.lightest',
}) => {
  const { formattedAmount, message } = useOffer();
  const link = message
    ? {
        text: 'Try now!',
        target: '/onboarding/',
      }
    : null;
  const reporter = useReporter();
  const trackEvent = headerBannerClicked(message, link);
  return (
    <StyledDiscountBanner
      $sticky={sticky}
      $bgColor={bgColor}
      $fontColor={fontColor}
      $marginBottom={croCopy ? '4px' : '0'}
    >
      {croCopy ? (
        <OfferBannerText>
          {interpolate(croCopy, { offer: formattedAmount })}
        </OfferBannerText>
      ) : (
        message && <OfferBannerText>{message}</OfferBannerText>
      )}
      {!croCopy && link && (
        <OfferLink href={link.target} onClick={() => reporter.tag(trackEvent)}>
          <OfferBannerText>{link.text}</OfferBannerText>
        </OfferLink>
      )}
    </StyledDiscountBanner>
  );
};

DiscountBanner.displayName = 'DiscountBanner';

DiscountBanner.propTypes = {
  sticky: PropTypes.bool,
  croCopy: PropTypes.string,
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
};
